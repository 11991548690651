import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StartPage from './StartPage';
import Dashboard from './Dashboard';
import Pricing from './Pricing';
import Login from './Login';
import SignUp from './SignUp';
import Configuration from './Configuration';
import CallSummaries from './CallSummaries';
import Legal from './Legal';
import DSGVO from './DSGVO';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route index element={<StartPage />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path='pricing' element={<Pricing />} />
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<SignUp />} />
        <Route path='configuration' element={<Configuration />} />
        <Route path='callsummaries' element={<CallSummaries />} />
        <Route path='impressum' element={<Legal />} />
        <Route path='dsgvo' element={<DSGVO />} />
      </Routes>
    </Router>
  
  );
}

export default App;
