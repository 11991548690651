import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Textarea, Label, Button, TextInput } from "flowbite-react";
import DashboardLayout from "./DashboardLayout";
import withAuth from "./withAuth";

function Configuration() {
  const [systemConfiguration, setSystemConfiguration] = useState("");
  const [redirects, setRedirects] = useState("");
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${api_url}/v1/configuration/${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
      });
        const data = await response.json();
        if (data.status === "success") {
          setSystemConfiguration(data.configuration.systemConfiguration);
          setRedirects(data.configuration.redirects);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [userId]);

  const saveConfiguration = async () => {
    try {
      const response = await fetch(`${api_url}/v1/configuration`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
      },
        body: JSON.stringify({ userId: userId, systemConfiguration: systemConfiguration, redirects: redirects }),
      });

      const data = await response.json();
      if (data.status === "success") {
        alert("Configuration saved successfully");
      } else {
        alert("An error occurred while saving the configuration");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Configuration
      </h5>

      <div className="mb-2 block">
        <Label htmlFor="knowledge" value="System Configuration" />
        <Textarea
          id="knowledge"
          value={systemConfiguration}
          onChange={(e) => setSystemConfiguration(e.target.value)}
          placeholder="Enter System Configuration and Knowledge Base..."
          rows={10}
        />
      </div>

      <div className="mb-2 block">
        <Label htmlFor="redirects" value="Redirects" />
        <Textarea
          id="redirects"
          value={redirects}
          onChange={(e) => setRedirects(e.target.value)}
          placeholder="Enter phone numbers and corresponding tasks..."
          rows={10}
        />
      </div>

      <div className="mb-2 flex">
        <Button onClick={saveConfiguration}>Save</Button>
        <Button color="gray" className="ml-2">
          Cancel
        </Button>
      </div>
    </DashboardLayout>
  );
}

export default withAuth(Configuration);
