import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Textarea, Label, Button, TextInput } from "flowbite-react";
import DashboardLayout from "./DashboardLayout";
import withAuth from "./withAuth";

function Dashboard() {
  const userId = localStorage.getItem("userId");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [greeting, setGreeting] = useState("");
  const [messageOfTheDay, setMessageOfTheDay] = useState("");

  const api_url = process.env.REACT_APP_API_URL


  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(`${api_url}/v1/configuration/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status === "success") {
          setPhoneNumber(data.configuration.phone);
          setGreeting(data.configuration.greeting);
          setMessageOfTheDay(data.configuration.motd)
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [userId]);

  const saveConfiguration = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(`${api_url}/v1/configuration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ userId: userId, phone: phoneNumber, greeting, motd: messageOfTheDay }),
      });

      const data = await response.json();
      if (data.status === "success") {
        alert("Configuration saved successfully");
      } else {
        alert("An error occurred while saving the configuration");
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (

    <DashboardLayout>

      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Welcome to CallSailor.
      </h5>

      <div>
        <div className="mb-2 block">
          <Label htmlFor="phone" value="Phone Number" />
        </div>
        <TextInput
          id="phone"
          // placeholder="+49 0000 0000"
          type="text"
          sizing="md"
          value={phoneNumber}
          disabled={true}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      <div className="mb-2 block">
        <Label htmlFor="greeting" value="Greeting" />
        <Textarea
          id="greeting"
          placeholder="Enter greeting text..."
          required={true}
          rows={4}
          value={greeting}
          onChange={(e) => setGreeting(e.target.value)}
        />
      </div>

      <div className="mb-2 block">
        <Label htmlFor="motd" value="Message of the day" />
        <Textarea
          id="motd"
          placeholder="Enter message of the day..."
          required={true}
          rows={4}
          value={messageOfTheDay}
          onChange={(e) => setMessageOfTheDay(e.target.value)}
        />
      </div>

      <div className="mb-2 flex">
        <Button onClick={saveConfiguration}>Save</Button>
        <Button color="gray" className="ml-2">
          Cancel
        </Button>
      </div>

    </DashboardLayout>

  );
}

export default withAuth(Dashboard);