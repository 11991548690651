import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Badge, Sidebar } from "flowbite-react"
import Layout from "./Layout";

function DashboardLayout({ children }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        navigate('/', { replace: true });
      };

  return (
<Layout>
    <div className="flex">
        <div className="w-1/4">
        <Sidebar aria-label="Sidebar">
            <Sidebar.Items>
            <Sidebar.ItemGroup>
                <Sidebar.Item
                // href="/dashboard"
                as={Link}
                to="/dashboard"
                // icon={HiChartPie}
                >
                Home
                </Sidebar.Item>
                <Sidebar.Item
                href="#"
                label="soon"
                // icon={HiViewBoards}
                >
                Call Summaries
                </Sidebar.Item>
                <Sidebar.Item
                // href="/configuration"
                as={Link}
                to="/configuration"
                // icon={HiInbox}
                >
                Configuration
                </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                <Sidebar.Item
                onClick={handleLogout}
                >
                Logout
                </Sidebar.Item>
            </Sidebar.ItemGroup>
            </Sidebar.Items>
        <Sidebar.CTA>
        <div className="mb-3 flex items-center">
        <Badge color="warning">
        Beta
        </Badge>
        <button
        aria-label="Close"
        className="-m-1.5 ml-auto inline-flex h-6 w-6 rounded-lg bg-blue-50 p-1 text-blue-900 hover:bg-blue-200 focus:ring-2 focus:ring-blue-400 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800"
        type="button"
        // onClick={() => {
        //     setIsBetaBadgeClosed(true);
        //     localStorage.setItem('isBetaBadgeClosed', true);
        // }}
        >
        <svg
            aria-hidden={true}
            className="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
            />
        </svg>
        </button>
        </div>
        <p className="mb-3 text-sm text-blue-900 dark:text-blue-400">
            This is the preview of CallSailor.
            Please be careful by using.
        </p>
        </Sidebar.CTA>
        </Sidebar>
    </div>

    <div className="w-3/4">
    <div className="w-9/12">

    <Card>
        {children}
    </Card>

    </div>
    </div>
    </div>
</Layout>

  );
}

export default DashboardLayout;


