import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Label, TextInput, Checkbox, Button } from "flowbite-react"
import Layout from "./Layout";
import axios from 'axios';

function Login() {

    const api_url = process.env.REACT_APP_API_URL

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");

    async function handleSubmit(event) {
        event.preventDefault();

        await axios.post(`${api_url}/v1/login`, { email, password }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const { status, token, userId } = response.data;
        
            // Save the token and userId in local storage or another secure storage solution
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);

            if (status === 'success') {
                window.location.href = '/dashboard';
            }
        })
        .catch(error => {
            let msg = error.response.data
            console.error(msg);
            alert(msg.message)
        });


    }


  return (
    <Layout>
        {/* <Card style={{marginLeft:'20%', marginRight: '20%', marginTop: '20pt'}}>
        </Card> */}

        <div className="w-80 mx-auto" style={{marginTop: "5%"}}>
            <Card>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Login
            </h5>
                <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div>
                    <div className="mb-2 block">
                    <Label
                        htmlFor="email"
                        value="Your email"
                    />
                    </div>
                    <TextInput
                    id="email"
                    type="email"
                    placeholder="name@email.com"
                    required={true}
                    onChange={(e) => setemail(e.target.value)}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                    <Label
                        htmlFor="password"
                        value="Your password"
                    />
                    </div>
                    <TextInput
                    id="password"
                    type="password"
                    required={true}
                    onChange={(e) => setpassword(e.target.value)}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="remember" />
                    <Label htmlFor="remember">
                    Remember me
                    </Label>
                </div>
                <Button type="submit">
                    Submit
                </Button>
                </form>
            </Card>
        </div>

</Layout>
  );
}

export default Login;