import { Card, Navbar, Dropdown, Avatar, Badge, Footer } from "flowbite-react"
import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from "react-router-dom";

function Layout({ children }) {
    
    const location = useLocation();
    const [isBetaBadgeClosed, setIsBetaBadgeClosed] = useState(false);

    useEffect(() => {
        const isClosed = localStorage.getItem('isBetaBadgeClosed') === 'true';
        setIsBetaBadgeClosed(isClosed);
    }, []);

    return (
        <div className="Layout">
        <Navbar
        fluid={true}
        rounded={true}
        >
        <Navbar.Brand href="/">
            <img
            src={`${process.env.PUBLIC_URL}/callsailor-logo.png`}
            className="mr-3 h-6 sm:h-9"
            alt="Callsailor Logo"
            />
            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
            CallSailor
            </span>
        </Navbar.Brand>
        <div className="flex md:order-2">
            {/* <Dropdown
            arrowIcon={false}
            inline={true}
            label={<Avatar alt="User settings" img="https://avatars.githubusercontent.com/u/23017105?v=4" rounded={true}/>}
            >
            <Dropdown.Header>
                <span className="block text-sm">
                Niklas Leinz
                </span>
                <span className="block truncate text-sm font-medium">
                niklas@corusm.de
                </span>
            </Dropdown.Header>
            <Dropdown.Item>
                Dashboard
            </Dropdown.Item>
            <Dropdown.Item>
                Settings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>
                Sign out
            </Dropdown.Item>
            </Dropdown>
            <Navbar.Toggle /> */}
        </div>
        <Navbar.Collapse>
            <Navbar.Link
            as={Link} to="/"
            active={location.pathname === "/"}
            >
            Home
            </Navbar.Link>
            <Navbar.Link as={Link} to="/dashboard" active={location.pathname === "/dashboard"} >
            Dashboard
            </Navbar.Link>
            <Navbar.Link as={Link} to="/pricing" active={location.pathname === "/pricing"}>
            Pricing
            </Navbar.Link>
        </Navbar.Collapse>
        </Navbar>

        {children}

        <Routes>
        <Route path="/dashboard" component={Dashboard} />
        </Routes>

        {!isBetaBadgeClosed && (
        <Card className="fixed bottom-5 left-5 mb-4 mr-4 z-50 w-1/5">
        <div className="mb-3 flex items-center">
            <Badge color="warning">
            Beta
            </Badge>
            <button
            aria-label="Close"
            className="-m-1.5 ml-auto inline-flex h-6 w-6 rounded-lg bg-blue-50 p-1 text-blue-900 hover:bg-blue-200 focus:ring-2 focus:ring-blue-400 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800"
            type="button"
            onClick={() => {
                setIsBetaBadgeClosed(true);
                localStorage.setItem('isBetaBadgeClosed', true);
            }}
            >
            <svg
                aria-hidden={true}
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
                />
            </svg>
            </button>
        </div>
        <p className="mb-3 text-sm text-blue-900 dark:text-blue-400">
            This is the preview of CallSailor.
            Please be careful by using.
        </p>
        </Card>)}

        <Footer container={true} className="bottom-0 fixed">
        <Footer.Copyright
            href="/"
            by="CallSailor"
            year={2023}
        />
        <Footer.LinkGroup>
            <Footer.Link  as={Link} to="/impressum">
            Impressum
            </Footer.Link>
            <Footer.Link as={Link} to="/dsgvo">
            DSGVO
            </Footer.Link>
        </Footer.LinkGroup>
        </Footer>
        </div>
    );
}

export default Layout;