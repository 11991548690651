import React from "react";
import { Link } from "react-router-dom";
import { Textarea, Label } from "flowbite-react"
import DashboardLayout from "./DashboardLayout";



function CallSummaries() {
  return (

    <DashboardLayout>

      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Call Summaries
      </h5>


    </DashboardLayout>

  );
}

export default CallSummaries;