import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const [isValid, setIsValid] = useState(false);
    const navigate = useNavigate();

    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/login', { replace: true });
      } else {
        const validateToken = async () => {
          try {
            const response = await fetch(`${api_url}/v1/validate-token`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
            });

            const data = await response.json();

            if (data.status === 'success') {
              setIsValid(true);
            } else {
              setIsValid(false);
              navigate('/login', { replace: true });
            }
          } catch (error) {
            console.error(error);
            setIsValid(false);
            navigate('/login', { replace: true });
          }
        };

        validateToken();
      }
    }, [navigate]);

    return isValid ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuth;
