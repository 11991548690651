import React from "react";
import { Card, Button } from "flowbite-react"
import Layout from "./Layout";

function DSGVO() {
  return (
    <Layout>
      <Card style={{ marginLeft: "20%", marginRight: "20%", marginTop: "20pt" }}>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Datenschutzerklärung
        </h5>
        <p className="text-gray-700 dark:text-gray-200">
          Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:
        </p>
        <p className="text-gray-700 dark:text-gray-200">
          Niklas Leinz <br />
          Einsteinstraße 3 <br />
          Garching bei München <br />
          E-Mail: niklas@leinz.dev
        </p>

        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white mt-4">
          Ihre Betroffenenrechte
        </h6>
        <p className="text-gray-700 dark:text-gray-200">
          Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:
        </p>
        <ul className="list-disc pl-6 text-gray-700 dark:text-gray-200">
          <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
          <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
          <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
        </ul>
        <p className="text-gray-700 dark:text-gray-200">
          Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
        </p>

        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white mt-4">
          Erhebung und Verarbeitung personenbezogener Daten
        </h6>
        <p className="text-gray-700 dark:text-gray-200">
          Wenn Sie unsere Website nutzen, ohne Angaben zu Ihrer Person zu machen, erheben und speichern wir keine personenbezogenen Daten.
        </p>

        <p className="text-gray-700 dark:text-gray-200">
          Wenn Sie sich auf unserer Website registrieren und einen Account anlegen, erheben wir personenbezogene Daten wie Ihren Namen, Ihre E-Mail-Adresse und Ihre Anrufdienstkonfiguration. Diese Daten werden in einer MongoDB-Datenbank gespeichert. Wir verwenden diese Informationen, um Ihnen unsere Dienste zur Verfügung zu stellen und Ihre Anfragen zu bearbeiten.
        </p>
        <p className="text-gray-700 dark:text-gray-200">
          Ihre Daten werden nur für den oben genannten Zweck verwendet und nicht an Dritte weitergegeben. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, da die Verarbeitung für die Erfüllung eines Vertrages oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
        </p>

        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white mt-4">
          Datensicherheit
        </h6>
        <p className="text-gray-700 dark:text-gray-200">
          Um Ihre persönlichen Daten zu schützen, verwenden wir SSL/TLS-Verschlüsselung für die Datenübertragung zwischen Ihrem Browser und unserem Server. Sie erkennen eine verschlüsselte Verbindung an dem "https://" in der Adresszeile und dem Schloss-Symbol in der Statusleiste Ihres Browsers.
        </p>

        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white mt-4">
          Löschung bzw. Sperrung der Daten
        </h6>
        <p className="text-gray-700 dark:text-gray-200">
          Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie es zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        </p>

        <h6 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white mt-4">
          Änderung unserer Datenschutzbestimmungen
        </h6>
        <p className="text-gray-700 dark:text-gray-200">
          Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
        </p>
      </Card>
    </Layout>


  );
}

export default DSGVO;

