import React from "react";
import { Card } from "flowbite-react";
import Layout from "./Layout";

function Legal() {
  return (
    <Layout>
      <Card style={{ marginLeft: "20%", marginRight: "20%", marginTop: "20pt" }}>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Impressum
        </h5>
        <p className="text-gray-700 dark:text-gray-200">
          Angaben gemäß § 5 TMG
        </p>
        <p className="text-gray-700 dark:text-gray-200">
          Niklas Leinz <br />
          Einsteinstraße 3 <br />
          Garching bei München
        </p>
        <p className="text-gray-700 dark:text-gray-200">
          Kontakt:<br />
          E-Mail: niklas@leinz.dev
        </p>
        <p className="text-gray-700 dark:text-gray-200">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
          Niklas Leinz<br />
          Einsteinstraße 3<br />
          Garching bei München
        </p>
      </Card>
    </Layout>
  );
}

export default Legal;
