import React from "react";
import { Link } from "react-router-dom";
import { Card, Navbar, Dropdown, Avatar, Badge, Footer, Textarea, Label } from "flowbite-react"
import Layout from "./Layout";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const api_url = process.env.REACT_APP_API_URL

function Pricing() {

    async function handleCheckout(priceId) {
        const stripe = await stripePromise;
        const token = localStorage.getItem("token");

        const response = await fetch(`${api_url}/v1/create-checkout-session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            priceId: priceId,
          }),
        });

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
    }

    const plans = [
        {
            title: "Low Demand",
            price: "9.99",
            monthlyCallCapacity: "8h",
            maxUsers: 5,
            priceId: "price_1Mn9MZGTy3x8eaVyFuvKNEP9",
        },
        {
            title: "Medium Demand",
            price: "19.99",
            monthlyCallCapacity: "16h",
            maxUsers: 20,
            priceId: "price_1Mn9MZGTy3x8eaVy72ZfbXcd",
        },
        {
            title: "High Demand",
            price: "199.99",
            monthlyCallCapacity: "160h",
            maxUsers: 40,
            priceId: "price_1Mn9MZGTy3x8eaVybuWcrjyi",
        },
    ];

    return (
        <Layout>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-8 items-center justify-center mx-auto">
                {plans.map((plan) => (
                    <Card key={plan.priceId}>
                        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
                            {plan.title}
                        </h5>
                        <div className="flex items-baseline text-gray-900 dark:text-white">
                            <span className="text-3xl font-semibold">€</span>
                            <span className="text-5xl font-extrabold tracking-tight">
                                {plan.price}
                            </span>
                            <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                                /month
                            </span>
                        </div>
                        <ul role="list" className="my-7 space-y-5">
                            <li className="flex space-x-3">
                                <svg
                                    className="h-5 w-5 shrink-0 text-blue-600 dark:text-blue-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                                    {plan.monthlyCallCapacity} monthly call capacity
                                </span>
                            </li>
                            <li className="flex space-x-3">
                                <svg
                                    className="h-5 w-5 shrink-0 text-blue-600 dark:text-blue-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                                    Max. {plan.maxUsers} users simultaneously
                                </span>
                            </li>
                        </ul>
                        <button
                            type="button"
                            onClick={() => handleCheckout(plan.priceId)}
                            className="inline-flex w-full justify-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900"
                        >
                            Choose plan
                        </button>
                    </Card>
                ))}
            </div>
            <div className="mt-8 text-center">
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    If no plan matches your needs, please{" "}
                    <a
                        href="mailto:niklas@corusm.de"
                        className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                        contact our sales team
                    </a>
                    .
                </p>
            </div>

        </Layout>
    );
}

export default Pricing;
