import React, { useState } from "react";
import { Card, Label, TextInput, Checkbox, Button, Toast } from "flowbite-react"
import Layout from "./Layout";
import axios from "axios";

function SignUp() {

  // Add state variables to store the form input values
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const api_url = process.env.REACT_APP_API_URL

  async function handleSubmit(event) {
    event.preventDefault();

    if (password !== repeatPassword) {
        // Display an error message or update the UI to reflect the error
        alert("Passwords do not match. Please try again.");
        return;
      }  

      const data = {
        name: name,
        surname: surname,
        email: email,
        password: password
      };
    
    await axios.post(`${api_url}/v1/signup`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        let msg = response.data
        console.log(msg);
        // alert(msg.message)
        if (msg.status == 'success') {
            window.location.href = '/login';
        }
    })
    .catch(error => {
        let msg = error.response.data
        console.error(msg);
        alert(msg.message)
    });

  }

  return (
    <Layout>

<div className="pt-10">
    <Card className="w-80 mx-auto">
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Sign Up
        </h5>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div className="flex gap-4">
                <div className="w-full">
                    <div className="mb-2 block">
                        <Label htmlFor="name" value="Name" />
                    </div>
                    <TextInput
                        id="name"
                        type="text"
                        placeholder="Your name"
                        required={true}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="w-full">
                    <div className="mb-2 block">
                        <Label htmlFor="surname" value="Surname" />
                    </div>
                    <TextInput
                        id="surname"
                        type="text"
                        placeholder="Your surname"
                        required={true}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="company" value="Company (optional)" />
                </div>
                <TextInput
                    id="company"
                    type="text"
                    placeholder="Your company name"
                    onChange={(e) => setCompany(e.target.value)}
                />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Your email" />
                </div>
                <TextInput
                    id="email"
                    type="email"
                    placeholder="name@email.com"
                    required={true}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="flex gap-4">
            <div>
                <div className="mb-2 block">
                <Label
                    htmlFor="password"
                    value="Your password"
                />
                </div>
                <TextInput
                id="password"
                type="password"
                required={true}
                shadow={true}
                onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div>
            <div className="mb-2 block">
                <Label
                    htmlFor="repeat-password"
                    value="Repeat password"
                />
                </div>
                <TextInput
                id="repeat-password"
                type="password"
                required={true}
                shadow={true}
                onChange={(e) => setRepeatPassword(e.target.value)}
                />
            </div>
            </div>
            <div className="flex items-center gap-2">
                <Checkbox id="agree" />
                <Label htmlFor="agree">
                I agree with the 
                <a
                    href="/forms"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                >
                    terms and conditions
                </a>
                </Label>
            </div>
            <Button type="submit">
                Register new account
            </Button>
        </form>
    </Card>
</div>


</Layout>
  );
}

export default SignUp;